@media (min-width: 1200px) {
	.container {
		max-width: 1400px; } }

.font-weight-black {
	font-weight: 900; }

.btn, .btn-lg {
	border-radius: 0;
	font-weight: 100; }

.grayscale {
	filter: grayscale(100%); }

.card {
	border-radius: 0; }

a.card {
	color: #212529;
	text-decoration: none;

	&:hover, &:active, &:focus {
		outline: none; } }

.top-0 {
	top: 0; }

.castoro {
	font-family: 'Castoro', serif; }
