// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";

// Theme
@import "theme";

// Slick Carousel
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

// Fancybox
@import "fancybox";

body {
	background-color: $body-bg;
	font-family: 'Montserrat', sans-serif;
	@include media-breakpoint-down(sm) {
		padding-top: 4.5rem; } }

h1, h2 {
	font-family: 'Bebas Neue', cursive; }

.navbar {
	@include media-breakpoint-down(sm) {
		background-color: #fff; } }

.navbar-brand {
	img {
		height: 3.5rem; }

	@include media-breakpoint-up(sm) {
		padding-top: 1.9rem!important;
		img {
			height: 5rem; } } }

.navbar-toggler {
	background-color: transparent;
	border: 1px solid #000;
	border-radius: 0;
	font-size: 1.3rem;
	padding-right: .8rem;
	padding-left: .8rem;

	&:focus, &:active {
		outline: 0; } }

.menu-container {
	@include media-breakpoint-down(sm) {
		width: 100%; }

	@include media-breakpoint-up(sm) {
		min-width: 80%;
		min-width: calc(78% - 7rem); } }

.mainmenu li {
	margin-right: .75rem;
	margin-left: .75rem;

	a {
		@extend .nav-link;
		color: #000;
		padding: .75rem 1rem;
		text-transform: uppercase; }

	&.active {
		a {
			text-decoration: underline; } } }

.languages {
	margin-right: 1rem;
	@include media-breakpoint-up(sm) {
		border-left: 1px solid #000;
		border-right: 1px solid #000;
		padding: .75rem 1.75rem;
		margin-bottom: 0; }

	@include media-breakpoint-down(sm) {
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		padding: .75rem 0; }

	li {
		a {
			color: #000;
			text-transform: uppercase;

			i svg {
				width: 30px;
				height: 30px; } } }

	li.active {
		display: none; } }

.navbar .socials {
	@include media-breakpoint-down(sm) {
		padding: 0 1rem 1rem {
	    text-align: center; } } }

.socials {
	li {
		a {
			background: #000;
			border-radius: 50%;
			color: #fff;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover, &:active, &:focus {
				text-decoration: none;
				outline: 0; } } } }

.home-slider {
	img {
 }		// filter: grayscale(100%)

	.slick-slide {
		border: 0; } }

#home_slide_ctrl {
	position: absolute;
	display: inline-block;
	width: 3.5rem;
	height: 7rem;
	padding-left: 0.5rem;
	left: 66.66666667%;
	bottom: 0;
	@include media-breakpoint-down(sm) {
		bottom: -1rem;
		left: 1rem; } }

.btn-slide-ctrl {
	background-color: rgba(theme-color("dark"), .7);
	width: 3rem;
	height: 3rem;
	font-size: 1.2rem;

	&.next {
		margin-bottom: 0.5rem; } }

.home-featured {
	position: relative;
	.home-featured-underlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%; }

	.featured-item .card-body {
		@include media-breakpoint-down(sm) {
			padding: 0rem 0rem 1rem 4.5rem; } } }

.featured-item {
	.card-body {
		padding: 4rem 3rem 4rem 5rem;
		font-family: 'Castoro', serif;
		@include media-breakpoint-down(sm) {
			padding: 0rem 0rem 4rem 2rem; }

		.icon {
			position: absolute;
			background-color: theme-color("warning");
			bottom: 0;
			left: 1rem;
			width: 3rem;
			display: block;
			text-align: center;
			font-size: 2rem; } } }

.events {
	background-color: #1a1a1a; }

.events-bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 4rem;
	left: 0;
	border-bottom: 4px solid theme-color("dark");
	// filter: grayscale(100%)

	&:after {
		content: '';
		display: block;
		background-color: rgba(theme-color("dark"), .7);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0; }

	div {
		background-size: cover;
		background-position: center; } }

.footer {
	h3 {
		font-weight: 500; } }

.footer-menu {
	ul {
		@extend .row;
		max-width: 280px;
		li {
			@extend .col-md-6;
			a {
				color: #ddd; } } } }

// About
.about-bg {
	@include media-breakpoint-up(sm) {
		position: absolute; } }

@media (min-width: 992px) {
	.about-bg-col {
		padding-right: 5rem!important; } }

.about-bg-col {
	@include media-breakpoint-down(sm) {
		padding: 0!important; } }

.about-board-col {
	padding-left: 4rem;
	padding-right: 4rem;
	position: relative;

	@include media-breakpoint-down(sm) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
		margin-bottom: 3rem; } }

.board-title {
	position: absolute;
	left: 5rem;
	bottom: 0; }

.board-slider {
	margin-right: -1rem;
	margin-left: -1rem; }

.btn-slick {
	border: 0;
	outline: 0;
	background: transparent;
	font-size: 2rem;
	line-height: 1;

	&:focus, &:active, &:hover {
		outline: 0; }

	svg {
		width: 2rem;
		height: 2rem; } }

.btn-slick-prev,
.btn-slick-next {
	position: absolute;
	top: 50%;
	top: calc(50% - 1rem); }

.btn-slick-prev {
	left: -2rem; }

.btn-slick-next {
	right: -2rem; }

.member {
	.member-details {
		padding: 0rem 3rem 3rem 5rem;

		@include media-breakpoint-down(sm) {
			padding: 0rem 0rem 3rem; } }

	.avatar {
		border: 3px solid theme-color("warning"); } }

.about-text-content {
	transition: height 200ms ease-in-out; }


.footer-socials {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
		display: inline-block;
		margin-right: .5rem;

		a {
			border: 1px solid #fff;
			color: #faaf3b;
			width: 2.5rem;
			height: 2.5rem;
			font-size: 1.5rem;
			padding: .1rem;
			text-align: center;
			border-radius: 10px;

			&:hover, &:active, &:focus {
				text-decoration: none;
				outline: 0; } } } }

.in {
	@extend .show; }

.nav-tabs {
	.nav-item {
		border-top: 1px solid #ebebeb;

		&:last-of-type {
			border-bottom: 1px solid #ebebeb; }

		.nav-link {
			border: 0;
			color: #212529;
			transition: padding-left 150ms ease;

			&:hover {
				padding-left: 2rem; }

			span {
				display: inline-block;
				border-left: 4px solid #faaf3b;
				padding-left: .5rem; } }

		&.active {
			.nav-link {
				padding-left: 2rem; } } } }

.finance, .pdf-in-list {
	a {
		color: #fff;
		font-size: 1.2rem;

		&:hover {
			text-decoration: none; }

		.file-title {
			font-size: 1.2rem; } } }

.fas.custom-pdf {
	display: inline-block;
	font-size: 2rem;
	background-color: #000;
	border: 2px solid #000;
	border-radius: 1px;
	position: relative;

	.triangle {
		position: absolute;
		top: -2px;
		right: -2px;
		width: 0;
		height: 0;
		border-top: 0.25rem solid theme-color('warning');
		border-right: 0.25rem solid theme-color('warning');
		border-bottom: 0.25rem solid transparent;
		border-left: 0.25rem solid transparent; } }



.project-item,
.event-item {
	margin-bottom: 6rem; }

.project-date,
.event-date {
	position: absolute;
	bottom: -3.9rem;
	left: 15px;
	@include media-breakpoint-up(sm) {
		right: 15px; } }

.single-project-date {
	h1, h2 {
		line-height: 1; }

	.the-date {
		line-height: 0.8;
		@include media-breakpoint-down(sm) {
			font-size: 3.8rem; } }
	h2 {
		@include media-breakpoint-down(sm) {
			font-size: 1.8rem; } }

	h1.display-4 {
		font-size: 2.2rem; }

	@include media-breakpoint-down(sm) {
		margin-left: auto; } }

.project-content {
	font-size: 1rem; }

a.card-video {
	position: relative;
	i.fab {
		opacity: 0.9;
		transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
		position: absolute;
		top: 45%;
		top: calc(50% - 2.25rem);
		left: 45%;
		left: calc(50% - 2.25rem); }

	&:hover {
		i.fab {
			color: red;
			opacity: 1; } } }

.share-links {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		margin-right: .5rem;

		a {
			border: 1px solid theme-color("warning");
			width: 2.5rem;
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			color: theme-color("dark");
			font-size: 1.4rem;
			border-radius: 10px;
			transition: background-color 200ms ease-in-out;

			&:hover, &:active, &:focus {
				text-decoration: none;
				background-color: theme-color("warning"); } } } }

.last-event-container {
	padding-top: 12rem; }

// Single event
.single-event-date,
.single-event-location,
.single-post-date {
	position: relative;
	overflow: hidden;
	.bg-icon {
		position: absolute;
		font-size: 8rem;
		top: 1rem;
		right: -1.5rem;
		line-height: 1;
		opacity: 0.25; } }

.event-location {
	p, h1, h2, h3, h4, h5, h6 {
		margin: 0!important; } }

.blog-slider-btn {
	position: absolute;
	bottom: -75px;
	right: -75px;
	width: 150px;
	height: 150px;
	padding: 62px 0; }



.blog-video-thumb {
	position: relative;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	height: 380px;
	i.fas {
		opacity: 0.9;
		position: absolute;
		top: 45%;
		top: calc(50% - 2.25rem);
		left: 45%;
		left: calc(50% - 2.25rem);
		color: $brand-warning;
		opacity: 1; } }

.blog-read-more {
	position: absolute;
	top: 1rem;
	bottom: -3.9rem;
	left: 15px;
	right: 15px;

	@media (max-width: 767px) {
		height: 220px;
		top: auto; } }

.single-post-date {
	position: absolute;
	bottom: -50px;
	left: -50px; }

.back {
	text-decoration: underline; }

.contact-info {
	i.fa, i.fas, i.fab {
		color: theme-color('warning'); } }

.contact-right {
	@include media-breakpoint-up(sm) {
		position: absolute;
		top: -100%;
		right: 0;
		bottom: 0;
		left: 0;
		height: 200%; } }

.contact-right {
	.form-control {
		border-bottom: 1px solid #fff!important;
		background-color: transparent;

		&:focus {
			outline: 0;
			box-shadow: none; } } }

.pagination {
	justify-content: center;

	li {
		@extend .page-item;
		a, span {
			@extend .page-link;
			color: #000;
			border-color: theme-color("warning"); }

		&.active {
			span {
				background-color: theme-color("warning");
				border-color: theme-color("warning"); } } } }

.page-item.active .page-link,
.pagination li.active .page-link,
.page-item.active .pagination li a,
.pagination li .page-item.active a,
.pagination li.active a,
.page-item.active .pagination li span,
.pagination li .page-item.active span,
.pagination li.active span {
	background-color: theme-color("warning");
	border-color: theme-color("warning"); }

.about-tabs {
	.featured-item .card-body .icon {
		width: 4rem;
		@include media-breakpoint-down(sm) {
			width: 3rem; } } }

.item-partner {
	border: 1px solid #eee; }

.form-newsletter {
	.form-control {
		border: 0;
		background-color: transparent;
		color: theme-color("warning");
		border-radius: 0;
		border-bottom: 2px solid #fff;

		&:focus {
			outline: 0;
			box-shadow: none; } }

	.btn {
		background-color: transparent;
		border: 0;
		color: #fff;
		border-bottom: 2px solid theme-color("warning");

		&:hover, &:focus, &:active, &:focus:active {
			background-color: transparent;
			border: 0;
			box-shadow: none;
			color: #fff;
			border-bottom: 2px solid theme-color("warning");
			outline: 0; } } }
